@import "./normalize.less";

*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html, body {
    font-family: Monospace;
    font-size: 16px;
    font-weight: 400;
    line-height: 165%;
    color: #000;
    background-color: #fff;
    /* height: 100%; */
}

body {
    display: flex;
    overflow-x: hidden;
}

#mount {
    flex: 1 1 0;
    display: flex;
    min-height: 667px; /* iPhone */
    max-width: 375px;
}

.code-screen {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 20px;
    background: #fafaf4;
}

.content-screen {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 20px;
    background: #fafaf4;
}

.content-container {
    flex: 1 1 0;
    display: flex;
    align-items: center;
}

.content-container.block {
    display: block;
}

.content-container .content-inner {
    flex: 1 1 0;
}

.content-container .content {
    font-size: 16px;
    font-family: Georgia;
    color: #3a3a3a;
    opacity: 0;
}

.content-container .content p:first-child {
    margin-top: 0;
}

.content-container .content p.nm:last-child {
    margin-bottom: 15px;
}

.content-container .symbol {
    opacity: 0;
    text-align: center;
    // padding-bottom: 30px;
    padding: 30px 0 50px 0;

    img {
        // max-width: 200px;
        max-width: 220px;
    }
}

.content-container .continue {
    opacity: 0;
    padding: 0;
    background: none;
    border: 0;
    width: 80px;
    height: 80px;
    cursor: pointer;
    margin: 0 auto;
    display: block;
    border: 1px solid #000;
}

.content-container .continue .shaft {
    position: relative;
    width: 1px;
    height: 40px;
    background-color: #000;
    display: block;
    margin: 0 auto;
    will-change: transform;
    transition: background-color 120ms ease;
}

.content-container .continue:hover {
    background-color: #eee;
}

.content-container .continue .shaft:before {
    width: 1px;
    background-color: #000;
    content: '';
    display: block;
    height: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.content-container .continue .shaft:after {
    width: 1px;
    background-color: #000;
    content: '';
    display: block;
    height: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.content-container .continue .shaft:before {
    transform: rotate(-40deg);
    transform-origin: bottom left;
}
.content-container .continue .shaft:after {
    transform: rotate(40deg);
    transform-origin: bottom right;
}

.content-container .timeline {
    opacity: 0;
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-left: 1px solid black;
    border-right: 1px solid black;
    margin: 60px 0 60px 0;
}
.content-container .timeline-line {
    opacity: 0;
    flex: 1 1 0;
    width: 100%;
    position: relative;
    height: 1px;
    background-color: #000;
}
.content-container .timeline-marker {
    opacity: 0;
    position: absolute;
    top: -6px;
    left: 20%;
    background-color: #000;
    width: 1px;
    height: 6px;
}

.content-container .timeline-circle {
    opacity: 0;
    position: absolute;
    bottom: 8px;
    left: -5.5px;

    width: 12px;
    height: 12px;
    border-radius: 13px;
    background-color: #ddd;
    border: 1px solid #3a3a3a;
}

.content-container .timeline.B .timeline-marker {
    top: 1px;
}

.content-container .timeline.B .timeline-circle {
    bottom: -15px;
}

.content-container .back-button {
    opacity: 0;
    cursor: pointer;
    background: none;
    border: 1px solid #000;
    width: 60px;
    height: 60px;
    display: block;
    padding: 0;
    margin: 0 auto 30px auto;
    transition: background-color 120ms ease;
}

.content-container .back-button:hover {
    background-color: #ddd;
}

.content-container .back-button .shaft {
    width: 25px;
    margin: 0 auto;
    background-color: #000;
    display: block;
    height: 1px;
    position: relative;
    will-change: transform;
}

.content-container .back-button .shaft:before,
.content-container .back-button .shaft:after {
    width: 8px;
    background-color: #000;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
}

.content-container .back-button .shaft:before {
    transform: rotate(40deg);
    transform-origin: top left;
}
.content-container .back-button .shaft:after {
    transform: rotate(-40deg);
    transform-origin: bottom left;
}

.hidden-title  {
    position: relative;
    margin-bottom: 15px;
}

.hidden-title .ls-outer {
    overflow: hidden;
    height: 24px;
    display: flex;
    margin: 0.6em 0;
}

.hidden-title .ls {
    display: flex;
    flex-direction: column;
    margin-right: 1px; /* Acts as letter-spacing */
    opacity: 0;
}

.hidden-title .l {
    font-size: 20px;
    height: 24px;
    line-height: 24px;
    width: 13px;
    font-family: Monospace;
}

.hidden-title .line {
    height: 1px;
    background: #000;
    opacity: 0;
    max-width: 160px;
    transform-origin: 0% 0%;
}

.hidden-title .coordinates-small {
    letter-spacing: 1px;
    font-size: 13px;
    color: #3a3a3a;
    opacity: 0;
    cursor: pointer;

    &:hover {
        color: #1a1a1a;
    }
}

.hidden-title .back-button {
    opacity: 0;
    cursor: pointer;
    background: none;
    padding: 0;
    border: 1px solid #000;
    width: 44px;
    height: 40px;
    display: block;
    position: absolute;
    top: 10px;
    right: 0;
}

.hidden-title .back-button:hover {
    background-color: #ddd;
}

.hidden-title .back-button .shaft {
    width: 20px;
    margin-left: 12px;
    background-color: #000;
    display: block;
    height: 1px;
    position: relative;
    will-change: transform;
}

.hidden-title .back-button .shaft:before,
.hidden-title .back-button .shaft:after {
    width: 8px;
    background-color: #000;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
}

.hidden-title .back-button .shaft:before {
    transform: rotate(40deg);
    transform-origin: top left;
}
.hidden-title .back-button .shaft:after {
    transform: rotate(-40deg);
    transform-origin: bottom left;
}

.coordinates {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    letter-spacing: 1px;

    // Topbar = ~90, footer = ~30
    margin-top: -60px;
}

.coordinates-inner {
    position: relative;
    overflow: hidden;
    height: 22px;
    display: flex;
    margin: 0 auto;
    cursor: pointer;

    &:hover .l {
        color: #1a1a1a;
    }
}

.coordinates .ls {
    display: flex;
    flex-direction: column;
    opacity: 0;
}

.coordinates .l {
    font-size: 18px;
    height: 22px;
    color: #3a3a3a;
    width: 12px;
    font-family: Monospace;
}

.coordinates .divider {
    display: inline-block;
    width: 20px;
}

.title h1 {
    opacity: 0;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: normal;
}

.title h2 {
    opacity: 0;
    font-family: Georgia;
    font-weight: normal;
    font-style: italic;
    font-size: 14px;
    color: #3a3a3a;
}

.title .line {
    height: 1px;
    background: #000;
    opacity: 0;
    transform-origin: 0% 0%;
    max-width: 140px;
}

.code-input {
    flex: 1 1 0;
    display: flex;
    align-items: center;
}

.code-input-inner {
    flex: 1 1 0;
}

.code-input form {
    display: flex;
}

.code-input input {
    opacity: 0;
    background: none;
    border: 0;
    flex: 1 1 0;
    font-size: 16px;
    font-family: Monospace;
    padding: 6px 0px;
    outline: none;
}

.code-input input::placeholder {
    font-family: Monospace;
    color: #6a6a6a;
    font-size: 16px;
}

.code-input button {
    opacity: 0;
    padding: 0;
    background: none;
    border: 0;
    width: 42px;
    cursor: pointer;
    transition: background-color 120ms ease;
}

.code-input button:hover .shaft,
.code-input button:hover .shaft:before,
.code-input button:hover .shaft:after {
    background-color: #666;
}

.code-input button .shaft {
    width: 42px;
    background-color: #000;
    display: block;
    height: 1px;
    position: relative;
    will-change: transform;
}

.code-input button .shaft:before,
.code-input button .shaft:after {
    width: 8px;
    background-color: #000;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    right: 0;
}

.code-input button .shaft:before {
    transform: rotate(40deg);
    transform-origin: top right;
}
.code-input button .shaft:after {
    transform: rotate(-40deg);
    transform-origin: bottom right;
}

.code-input .line {
    height: 1px;
    background: #000;
    opacity: 0;
}

.code-input.err .line,
.code-input.err .shaft,
.code-input.err .shaft:before,
.code-input.err .shaft:after {
    background-color: red;
}

footer {
    opacity: 0;
    margin-top: 20px;
    padding-bottom: 10px;
}

footer .footer-inner {
    display: flex;
    align-items: center;
}

footer .footer-inner div {
    flex: 1 1 0;
    color: #3a3a3a;
    font-family: Georgia;
    font-style: italic;
    font-size: 14px;
}

footer .right {
    text-align: right;
}

@media screen and (min-width: 768px) {
    #mount {
        margin: 20px auto 0 auto;
    }
}

@media screen and (max-width: 767px) {
    html, body {
        height: 100%;
        background: #fafaf4;
    }

    #mount {
        margin: 0 auto;
        min-height: auto;
        height: 100%;
        max-width: 420px;
    }
}
